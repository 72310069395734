import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import FooterWhite from 'components/Footers/FooterWhite.js';

const contact = {
  address: 'Reichelstraße 7',
  zip: '95326',
  location: 'Kulmbach',
  phone: '+49 9221 95 02 0',
};

const addCol = content => {
  return (
    <Col md="12">
      <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
      <label style={{ paddingLeft: '1rem' }}>{content}</label>
    </Col>
  );
};

const addPara = (title, content) => {
  return (
    <div>
      <p>
        <h4>{title}</h4>
        {content}
      </p>
    </div>
  );
};

function Impressum() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <div className="wrapper" style={{ padding: '2rem' }}>
        <div className="section landing-section">
          <Container>
            <Row
              style={{ fontSize: '18px', color: 'inherit', fontWeight: '500' }}>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center" style={{ paddingBottom: '2rem' }}>
                  Impressum
                </h2>
                <br />
                <br />
                <br />
                <Row>
                  {addCol('Schuberth & Schuberth')}
                  {addCol('Versicherungsagentur')}
                  {addCol('Maximilian Schuberth')}
                  <Col md="12">
                    <i className="fa fa-map-pin"></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      {contact.address}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      {contact.zip} {contact.location}
                    </label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-phone"></i>
                    <a
                      href="tel:+49922195020"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      {contact.phone}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-fax"></i>
                    <a
                      href="tel:+499221950225"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 25
                    </a>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-envelope"></i>
                    <a
                      href="mailto:schuberth@schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      schuberth@schuberth-versicherungen.de
                    </a>
                  </Col>
                  <Col md="12">
                    <i className="fa fa-envelope" style={{ opacity: '0' }}></i>
                    <a
                      href="https://www.schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        fontWeight: '500',
                      }}>
                      www.schuberth-versicherungen.de
                    </a>
                  </Col>

                  <br />
                  <br />
                  <br />

                  {addPara(
                    'Verantwortlich für den Inhalt: Maximilian Schuberth',
                  )}
                  {addPara('Einzelunternehmen mit Firmensitz in Kulmbach')}
                  {addPara(
                    'Gebundener Versicherungsvertreter',
                    ' gem. § 34 d Abs. 4 GewO, Register-Nr. D-LPN9-DPVNZ-02, mit uneingeschränkter Haftungsübernahme für die Vermittlungstätigkeit durch ein Versicherungsunternehmen der ERGO',
                  )}
                  {addPara(
                    'Finanzanlagenvermittler',
                    ' gem. § 34 f Abs. 1 Satz 1 Nr. 1 GewO, Register-Nr. D-F-155-L119-70;            Ich bin zur Vermittlung von Finanzanlagen der MEAG MUNICH ERGO Kapitalanlagegesellschaft mbH sowie von ausgewählten Investmentfonds von Drittanbietern berechtigt',
                  )}
                  {addPara(
                    '',
                    'Vergütung der Geschäftstätigkeit erfolgt durch Provisionszahlungen bzw. sonstige Zuwendungen von der ERGO Beratung und Vertrieb AG. ',
                  )}
                  {addPara(
                    'Zuständige Berufskammer:',
                    'IHK für Oberfranken Bayreuth, Bahnhofstraße 25, 95444 Bayreuth',
                  )}
                  {addPara(
                    'Zuständige Aufsichtsbehörde:',
                    'IHK für München und Oberbayern, Max-Joseph-Straße 2, 80333 München, www.muenchen.ihk.de',
                  )}
                  {addPara(
                    'Berufsrechtliche Regelungen:',
                    '§ 34 d Gewerbeordnung, §§ 59-68 VVG, VersVermV',
                  )}
                  {addPara(
                    '',
                    'Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage www.gesetze-im-internet.de eingesehen und abgerufen werden. ',
                  )}
                  {addPara(
                    '',
                    'Die Eintragung im Vermittlerregister kann wie folgt überprüft werden:                                       Deutscher Industrie- und Handelskammertag (DIHK) e.V.,11052 Berlin, Telefon: 0 180/60 05 85 0* www.vermittlerregister.org',
                  )}
                  {addPara(
                    '',
                    'Information zu den Schlichtungsstellen gemäß § 214 VVG und zur Teilnahme am Streitbeilegungsverfahren gemäß § 36 Verbraucherstreitbeilegungsgesetz.',
                  )}
                  {addPara(
                    '',
                    'Sollten Sie im Zusammenhang mit der Versicherungsvermittlung Anlass zur Beschwerde haben, so können Sie sich auch an unser Kundenservice-Center, Telefon 0800-3746 017, wenden. Darüber hinaus stehen Ihnen bei Beschwerden auch die außergerichtliche Beschwerde- und Schlichtungsstelle beim "Versicherungsombudsmann e.V.", Postfach 080632 in 10006 Berlin, beim "Ombudsmann Private Kranken- und Pflegeversicherung", Postfach 060222, 10052 Berlin und bei der "Ombudsstelle für Investmentfonds des BVI Bundesverband Investment und Asset Management e.V.", Unter den Linden 42, 10117 Berlin zur Verfügung.  ',
                  )}
                  <div>
                  <p>
                    <h4></h4>
                      Online-Streitbeilegung gemäß Art. 14 Abs. 1 Verordnung über Online- Streitbeilegung in Verbraucherangelegenheiten (ODR-VO):
                      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OSl bereit. Die Plattform finden Sie unter <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.
                      Verbraucher haben die Möglichkeit, diese Plattform für außergerichtliche Beilegung ihrer Streitigkeiten über vertragliche Verpflichtungen zu nutzen.
                      Unsere e-Mail-Adresse ist: schuberth@schuberth-versicherungen.de
                    </p>
                    <p>
                      Wir sind weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                  </div>
                  {addPara(
                    'Beschwerdemanagement',
                    'Bei Beschwerden über unsere Tätigkeit wenden Sie sich gerne an unsere interne Beschwerdestelle: schuberth@schuberth-versicherungen.de',
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Impressum;
