import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { isMobile } from 'react-device-detect';

// core components
const images = {
  max_large: require('assets/img/max_header.jpg'),
  max_small: require('assets/img/_max_zoom.jpg'),
  charlie_large: require('assets/img/charlie_header.jpg'),
  charlie_small: require('assets/img/charlie_small.jpg'),
};

const navigateToContact = () =>
{
  const contactSection = document.querySelector('.contact-section');
  if (contactSection)
  {
    contactSection.scrollIntoView();
  }
};

function LandingPageHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div className="page-header" ref={pageHeader}>
        <div className="filter" />
        <div className="img-container">
          <picture>
            <source media="(min-width: 600px)" srcset={images.max_large} />
            <source media="(max-width: 600px)" srcset={images.max_small} />
            <source srcset={images.max_small} />
            <img className="img-container--max" src={images.max_large} alt="" />
          </picture>
          <div className="mobile-placeholder">
            <img src={require('assets/img/logo.jpg')}></img>
          </div>
          <picture>
            <source media="(min-width: 600px)" srcset={images.charlie_large} />
            <source media="(max-width: 600px)" srcset={images.charlie_small} />
            <source srcset={images.charlie_small} />
            <img
              className="img-container--charlie"
              src={images.charlie_large}
              alt=""
            />
          </picture>
        </div>
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">
                <img src={require('assets/img/sus_blau.jpg')}></img>
              </h1>
              <h3 className="description" style={{ fontWeight: '500' }}>
                Ihre Konstante seit über 40 Jahren
              </h3>
              <br />
              {isMobile ? 
              ( <Button
                className="btn btn-outline-neutral btn-lg"
                color="white"
                href="tel:+499221950225"
                target="_blank">
                <i className="fa fa-play" style={{ fontWeight: '500' }} />
                Kontakt aufnehmen
              </Button>) : ( <Button
                className="btn btn-outline-neutral btn-lg"
                  color="white"
                  onClick={navigateToContact}
                target="_blank">
                <i className="fa fa-play" style={{ fontWeight: '500' }} />
                Kontakt aufnehmen
              </Button>)}
             
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
