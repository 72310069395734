import React from 'react';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

// core components
import LandingPageHeader from 'components/Headers/LandingPageHeader.js';
import FooterWhite from 'components/Footers/FooterWhite.js';

//helper
import { createTeamMember } from './OurTeam';
import Mail from '../components/Mail/index';

import imageVideo from '../assets/video/image-video.mp4';
import videoThumbnail from '../assets/img/sus_blau.jpg';

const addCol = content => {
  return (
    <Col md="12">
      <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
      <label style={{ paddingLeft: '1rem' }}>{content}</label>
    </Col>
  );
};

function LandingPage() {
  return (
    <>
      <LandingPageHeader />
      <div className="wrapper">
        <div className="s-timeline">
          <div className="s-container left" style={{marginBottom: '480px', paddingRight:'4rem'}}>
            <div className="s-content">
            <h2>Warum wir?</h2>
              <p>
                <video height="480" widht="480" controls src={imageVideo} poster={videoThumbnail} />
              </p>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Familienunternehmen</h2>
              <p>
                Unser Familienunternehmen steht seit mehr als 40 Jahren für
                generationenübergreifende Kompetenz und Konstanz in der
                persönlichen, individuellen Kundenbeziehung. Wir freuen uns
                darüber, dass unser Einsatz den Menschen aus der Region das gute
                Gefühl ermöglicht, ihren Status quo genauso gesichert zu wissen,
                wie ihre Zukunft. Für dieses Vertrauen bedanken wir uns recht
                herzlich.
              </p>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Unsere Leistung</h2>
              <p>
                Wir verstehen uns als Ihre ganz persönliche{' '}
                VERSICHERUNGSABTEILUNG:{' '}
                <strong>
                  Beratung. Begleitung. Prüfung. Korrespondenz. Durchsetzung.
                </strong>
              </p>
              <p>
                In allen Versicherungs- und Finanzfragen sind wir gerne für Sie
                da. Ganz gleich, ob es um Sie selbst, Ihre Familie oder Ihr
                Unternehmen geht.
              </p>
            </div>
          </div>
          <div className="s-container left last">
            <div className="s-content">
              <h2>Unser Ziel</h2>
              <p>
                Wir möchten Ihre <strong>Konstante</strong> in Versicherungs-
                und Finanzfragen sein - wie in der Vergangenheit so auch in der
                Zukunft. Gerne lebenslang.
              </p>
            </div>
          </div>
        </div>

        <div className="section section-blue text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2
                  className="title"
                  style={{ textAlign: 'center', color: 'white' }}>
                  Zuhören. Verstehen. Versichern.
                </h2>
                <img
                  src={require('assets/img/sch_diagramm.png')}
                  style={{ width: '100%' }}
                />
                <br />
                <Button
                  className="btn btn-outline-neutral btn-lg btn btn-white"
                  color="danger"
                  href="https://maximilian-schuberth.ergo.de/de/Produkte"
                  target="_blank"
                  style={{ marginTop: '26px' }}>
                  Zu den Produkten
                </Button>
              </Col>
            </Row>
            <br />
            <br />
          </Container>
        </div>
        <div className="section section-white text-center landing-section">
          <Container>
            <h2 className="title">Unser Team</h2>
            <Row>
              {createTeamMember({
                imgPath: require('assets/img/max_small.jpg'),
                name: 'Maximilian Schuberth',
                position: 'Agenturinhaber',
                description: 'Fachwirt für Versicherungen und Finanzen',
                subDescription: '(IHK) seit 2008',
                additionalBtn: {
                  className: 'btn ergo-hp-link',
                  text: 'ERGO Beratung und Vertrieb AG',
                  link: 'https://maximilian-schuberth.ergo.de/de/Startpage/Startseite(AGT)',
                },
              })}
              {createTeamMember({
                imgPath: require('assets/img/charlie_small.jpg'),
                name: 'Manfred A. Schuberth',
                position: 'Seniorpartner',
                description: 'Versicherungsfachmann',
                subDescription: '(BWV) seit 1978',
              })}
              {createTeamMember({
                imgPath: require('assets/img/silvia_small.jpg'),
                name: 'Silvia Schlenk',
                position: 'Büroleitung',
                description: 'Versicherungskauffrau/ Betriebswirtin',
                subDescription: '(VWA) seit 1987',
              })}
            </Row>
          </Container>
        </div>
        <Mail />
        <div className="section section-white landing-section contact-section">
          <Container>
            <Row style={{ fontSize: '18px', fontWeight: '500' }}>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center"style={{ paddingBottom: '2rem' }}>
                  Kontakt
                </h2>
                <Row>
                  {addCol('Schuberth & Schuberth')}
                  {addCol('Versicherungsagentur')}
                  {addCol('Maximilian Schuberth')}
                  <Col md="12">
                    <i className="fa fa-map-pin"></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      Reichelstraße 7
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      95326 Kulmbach
                    </label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-phone"></i>
                    <a
                      href="tel:+49922195020"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 0
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-fax"></i>
                    <a
                      href="tel:+499221950225"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 25
                    </a>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-envelope"></i>
                    <a
                      href="mailto:schuberth@schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      schuberth@schuberth-versicherungen.de
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
